<template>
  <div>
    <el-page-header @back="dynamic" content="详细信息" style="margin-bottom: 15px;">
    </el-page-header>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-descriptions :title="peopleById.peoName">
          <el-descriptions-item label="标签">
            <div v-for="(item, index) in peopleById.tag" :key="index" style="float: left; margin-right: 10px">
              <el-tag :type=item.tagType size="mini">{{ item.tagName }}</el-tag>
            </div>

          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="text item ql-container" style="min-height: 300px">
        <div class="ql-editor" v-html="peopleById.peoInfomation"></div>
      </div>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-avatar shape="square" :size="40">匿名</el-avatar>

    </el-card>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  name: "reader",
  data() {
    return {
      peopleById: ''
    }
  },
  mounted() {
    this.getPeopleById(this.$route.query.peoId)
  },
  methods: {
    dynamic() {
      this.$router.push("../people")
    },
    getPeopleById(peoId) {
      this.$http({
        method: 'get',
        url: '/api/people/getPeoById/' + peoId
      }).then((res) =>{
        this.peopleById = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>